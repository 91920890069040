import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(private http: HttpClient) { }

    getOrders(type) {
        let url = `/api/payments/orders`;
        url += `?status=` + type;
        return this.http.get<any>(environment.api_base_url + url)
            .pipe(map(res => {
                return res;
            }));
    }

    getOrderById(orderId, token) {
        return this.http.get<any>(environment.api_base_url + `/api/payments/orders/` + orderId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .pipe(map(res => {
                return res;
            }));
    }

    getInvoiceUrl(orderId) {
        return this.http.get<any>(environment.api_base_url + `/invoice/` + orderId)
            .pipe(map(res => {
                return res;
            }));
    }

    createOrder(order) {
        return this.http.post<any>(environment.api_base_url + `/api/payments/orders`, order)
            .pipe(map(res => {
                return res;
            }));
    }

    updateOrder(order) {
        return this.http.put<any>(environment.api_base_url + `/api/payments/orders/` + order.id, order)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteOrder(orderId) {
        return this.http.delete<any>(environment.api_base_url + `/api/payments/orders/` + orderId)
            .pipe(map(res => {
                return res;
            }));
    }

    createCustomer(paymentRequest, orderId, token) {
        return this.http.post<any>(environment.api_base_url + `/api/payments/customers/` + orderId, paymentRequest, {
            headers: {
                Authorization: `Bearer ${token ? token : environment.api_token}`
            }
        })
            .pipe(map(res => {
                return res;
            }));
    }

    cancelOrder(orderId, data, token) {
        return this.http.post<any>(environment.api_base_url + `/api/payments/cancel/order/` + orderId, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .pipe(map(res => {
                return res;
            }));
    }

    markOrderAsPaid(orderId, data) {
        return this.http.post<any>(environment.api_base_url + `/api/payments/paid/order/` + orderId, data)
            .pipe(map(res => {
                return res;
            }));
    }

    refundOrder(orderId, data) {
        return this.http.post<any>(environment.api_base_url + `/api/payments/refund/order/` + orderId, data)
            .pipe(map(res => {
                return res;
            }));
    }

    markOrderAsRefunded(orderId) {
        return this.http.get<any>(environment.api_base_url + `/api/payments/refund/order/` + orderId)
            .pipe(map(res => {
                return res;
            }));
    }

    getSepaExports() {
        return this.http.get<any>(environment.api_base_url + `/api/sepa-exports`)
            .pipe(map(res => {
                return res;
            }));
    }

    markAsTreatedSepaExport(sepaExportId) {
        return this.http.post<any>(environment.api_base_url + `/api/treat-sepa-export/` + sepaExportId, {})
            .pipe(map(res => {
                return res;
            }));
    }

    generateSepaExport(bankingDate) {
        return this.http.post<any>(environment.api_base_url + `/api/generate-sepa-export`,
            { banking_date: bankingDate })
            .pipe(map(res => {
                return res;
            }));
    }

    getCountriesList() {
        return this.http.get<any>(environment.trevex_api_base_url+`/api/nationalities`)
          .pipe(map(res => {
            return res;
        }));
    }


}
