import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

	constructor(private spinner: NgxSpinnerService, 
 				private router: Router) {

	 	router.events.subscribe( (event: Event) => {

	        if (event instanceof NavigationStart) {
	    		this.spinner.show();   
	        }

	        if (event instanceof NavigationEnd) {
	        	setTimeout(() => {
			      this.spinner.hide();
			    }, 2000);
	        }

	        if (event instanceof NavigationError) {
	        	console.log('NavigationError');
	            
	        }
	    });

 	}

 	ngOnInit() {

	    this.spinner.show();
	    setTimeout(() => {
	      this.spinner.hide();
	    }, 5000);
	 
	    
	  }

}	
