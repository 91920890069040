import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PaymentService } from '../../core/services/payment.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit {
  paymentId: any = null;
  result: any = null;
  website:string = '';

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private configurationService: ConfigurationService,
  ) { }

  ngOnInit() {
    this.route.params
    .subscribe(
      (params: Params) => {
        if (params.payment_id) {
          console.log('params.payment_id', params.payment_id);
          this.paymentId = params.payment_id;
          this.getPaymentById();
        }
    });

    this.result = this.route.snapshot.queryParams.result;

  }

  getPaymentById() {
    // this.paymentService.getOrderById(this.paymentId)
    // .pipe(first())
    // .subscribe(
    // data => {
    // console.log('data', data);
    // },
    // error => {
    // console.log('error', error);
    // });

    this.configurationService.currentClientConfiguration()
    .pipe(first())
    .subscribe(
    data => {
      this.website = data.data.website;
    },
    error => {
      console.log('error', error);
    });
   }

}
