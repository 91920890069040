import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

    constructor(private http: HttpClient) { }

    addConfiguration(configuration) {
        return this.http.post<any>(environment.api_base_url + `/api/add-configuration`, configuration)
            .pipe(map(res => {
                return res;
            }));
    }

    addInvoiceConfiguration(invoiceconfiguration) {
        return this.http.post<any>(environment.api_base_url + `/api/add-invoice-configuration`, invoiceconfiguration)
            .pipe(map(res => {
                return res;
            }));
    }

    addEmailsConfiguration(emailsconfiguration) {
        return this.http.post<any>(environment.api_base_url + `/api/add-emails-configuration`, emailsconfiguration)
            .pipe(map(res => {
                return res;
            }));
    }

    currentClientConfiguration() {
        return this.http.get<any>(environment.api_base_url + `/api/configuration`)
            .pipe(map(res => {
                return res;
            }));
    }

    updateClientSiteConfiguration(configurationId, configuration) {
        configuration.configurationId = configurationId;
        return this.http.post<any>(environment.api_base_url + `/api/update-configuration/`+ configurationId, configuration)
            .pipe(map(res => {
                return res;
            }));
    }

    updateInvoiceConfiguration(invoiceId, invoice) {
        invoice.invoiceId = invoiceId;
        return this.http.post<any>(environment.api_base_url + `/api/update-invoice-configuration/`+ invoiceId, invoice)
            .pipe(map(res => {
                return res;
            }));
    }

    updateEmailsConfiguration(emailsId, emails) {
        emails.emailsId = emailsId;
        return this.http.post<any>(environment.api_base_url + `/api/update-emails-configuration/`+ emailsId, emails)
            .pipe(map(res => {
                return res;
            }));
    }

    getPaymentMethods(token) {
        return this.http.get<any>(environment.api_base_url + `/api/payment-methods`, { headers: {
            Authorization: `Bearer ${token ? token : environment.api_token}`
          }})
            .pipe(map(res => {
                return res;
            }));
      }

    createPaymentInvoiceConfig(invoiceconfig) {
        return this.http.post<any>(environment.api_base_url + `/api/add-payment-invoice-configuration`, invoiceconfig)
            .pipe(map(res => {
                return res;
            }));
    }

    createPaymentSepaConfig(sepaconfig) {
        return this.http.post<any>(environment.api_base_url + `/api/add-payment-sepa-configuration`, sepaconfig)
            .pipe(map(res => {
                return res;
            }));
    }

    createPaymentStripeConfig(stripeconfig) {
        return this.http.post<any>(environment.api_base_url + `/api/add-payment-stripe-configuration`, stripeconfig)
            .pipe(map(res => {
                return res;
            }));
    }

    createPaymentPaypalConfig(paypalconfig) {
        return this.http.post<any>(environment.api_base_url + `/api/add-payment-paypal-configuration`, paypalconfig)
            .pipe(map(res => {
                return res;
            }));
    }

    updatePaymentInvoiceConfig(invoiceConfigId, invoiceConfig) {
        invoiceConfig.invoiceConfigId = invoiceConfigId;
        return this.http.post<any>(environment.api_base_url + `/api/update-payment-invoice-configuration/`+ invoiceConfigId, invoiceConfig)
            .pipe(map(res => {
                return res;
            }));
    }

    updatePaymentSepaConfig(sepaConfigId, sepaConfig) {
        sepaConfig.sepaConfigId = sepaConfigId;
        return this.http.post<any>(environment.api_base_url + `/api/update-payment-sepa-configuration/`+ sepaConfigId, sepaConfig)
            .pipe(map(res => {
                return res;
            }));
    }

    updatePaymentStripeConfig(stripeConfigId, stripeConfig) {
        stripeConfig.stripeConfigId = stripeConfigId;
        return this.http.post<any>(environment.api_base_url + `/api/update-payment-stripe-configuration/`+ stripeConfigId, stripeConfig)
            .pipe(map(res => {
                return res;
            }));
    }

    updatePaymentPaypalConfig(paypalConfigId, paypalConfig) {
        paypalConfig.paypalConfigId = paypalConfigId;
        return this.http.post<any>(environment.api_base_url + `/api/update-payment-paypal-configuration/`+ paypalConfigId, paypalConfig)
            .pipe(map(res => {
                return res;
            }));
    }

    uploadLogo(file) {
        const data = new FormData();
        data.append('logo', file, file.name);
        return this.http.post<any>(environment.api_base_url + `/api/upload/logo`, data)
        .pipe(map(res => {
            return res;
        }));
    }

    addTemplateConfiguration(templateConfiguration){
        return this.http.post<any>(environment.api_base_url + `/api/add-template-configuration`, templateConfiguration)
        .pipe(map(res => {
            return res;
        }));
    }

    updateTemplateConfiguration(templateId, templConfiguration) {
        templConfiguration.templateId = templateId;
        return this.http.post<any>(environment.api_base_url + `/api/update-template-configuration/`+ templateId, templConfiguration)
            .pipe(map(res => {
                return res;
            }));
    }

}
