import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('current_user_upip'));
        }
        return this.user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return this.http.post<any>(environment.api_base_url + `/api/login`, { email, password })
            .pipe(map(res => {
                const {user, access_token, roles} = res;
                // login successful if there's a jwt token in the response
                if (user && access_token) {
                    user.firstName = user.first_name;
                    user.lastName = user.last_name;
                    user.token = access_token;
                    delete user.first_name;
                    delete user.last_name;
                    delete user.roles;
                    user.roles = roles;
                    this.user = user;
                    this.cookieService.setCookie('current_user_upip', JSON.stringify(user), 1); // store user details and jwt in cookie
                }
                return this.user;
            }));
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('current_user_upip');
        this.user = null;
    }

    /**
     * Reset password
     * @param email email of user
     */
    requestResetPassword(email: string) {
        return this.http.post<any>(environment.api_base_url + `/api/request-reset-password`, { email })
            .pipe(map(res => {
                return res;
            }));
    }

    /**
     * Check reset password token
     * @param email email of user
     * @param token token of reset password
     */
    checkResetPasswordToken(token: string) {
        return this.http.post<any>(environment.api_base_url + `/api/users/check-reset-password-token`, null, {
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
            .pipe(map(res => {
                const {success} = res;
                return success;
            }));
    }

    /**
     * Check reset password token
     * @param email email of user
     * @param newPassword new password
     * @param token token of reset password
     */
    resetPassword(token: string, newPassword: string) {
        return this.http.post<any>(environment.api_base_url + `/api/users/change-reset-password`, { newPassword }, {
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
            .pipe(map(res => {
                const {success} = res;
                return success;
            }));
    }
}

